export enum QueryKey {
	Artifacts = 'artifacts',
	Assignment = 'assignment',
	Assignments = 'assignments',
	Battery = 'battery',
	Batteries = 'batteries',
	BatteryIssue = 'battery-issue',
	BatteryResults = 'battery-results',
	CurrentUser = 'current-user',
	OptionalFields = 'optional-fields',
	Organization = 'organization',
	Patients = 'patients',
	Patient = 'patient',
	Questionnaire = 'questionnaire',
	Reasons = 'reasons',
	Roles = 'roles',
	Subject = 'subject',
	User = 'user',
	Users = 'users',
	UserOrganizations = 'user-organizations',
	ValueList = 'value-list',
	Zendesk = 'zendesk',
}
